import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Footer } from "../components/footer"
import { NavBar } from "../components/header"
import Layout from "../components/layout"
import Newsletter from "../components/newsletter"
import Seo from "../components/seo"

const Privacy = () => {
    return (
        <Layout>
            <Seo title="Privacy" />

            <div className="top-gradient-container">
                <NavBar image />
                <div className="container-xxl privacy">
                    <div className="row">
                        <div className="col-12 text-center z-index-3">
                            <h2 className="privacy__header">Privacy Policy</h2>
                        </div>
                        <div className="col-12 mb-2">
                            <h2 className="mb-4">Experience Booking</h2>
                            <p className="mb-4">
                                When booking your experience you will need to enter personal information. By doing so you accept that
                                the information provided may be used by the company, it’s partners and carefully selected third parties
                                for marketing purposes. Your financial information is secure and will never be disclosed to third
                                parties unless we are required to do so by law.
                            </p>
                        </div>
                        <div className="col-12">
                            <h2 className="mb-4">Unsubscribing</h2>
                            <p className="mb-4">
                                To unsubscribe from text message updates this can be done via the link within the text itself or by
                                contacting us directly. To unsubscribe from email updates click the unsubscribe link at the bottom of
                                the email. If you have any issues please use via{" "}
                                <a href="mailto:hello@maliblueyachts.com">hello@maliblueyachts.com</a>
                            </p>
                        </div>
                        <div className="col-12">
                            <h2 className="mb-4">What personal data we collect and why we collect it</h2>
                            <p className="mb-3">
                                <b className="privacy__bold">Contact Forms: Cookies</b>
                            </p>
                            <p className="mb-4">
                                If you leave a comment on our site you may opt-in to saving your name, email address and website in
                                cookies. These are for your convenience so that you do not have to fill in your details again when you
                                leave another comment. These cookies will last for one year. If you visit our login page, we will set a
                                temporary cookie to determine if your browser accepts cookies. This cookie contains no personal data
                                and is discarded when you close your browser.
                            </p>

                            <p className="mb-3">
                                <b className="privacy__bold">Embedded content from other websites</b>
                            </p>

                            <p className="mb-3">
                                Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded
                                content from other websites behaves in the exact same way as if the visitor has visited the other
                                website.
                            </p>

                            <p className="mb-5">
                                These websites may collect data about you, use cookies, embed additional third-party tracking, and
                                monitor your interaction with that embedded content, including tracking your interaction with the
                                embedded content if you have an account and are logged in to that website.
                            </p>
                        </div>
                    </div>
                </div>
                <StaticImage className="bg-contact-water" src="../images/water1.png" alt="water" />
            </div>

            <Newsletter />
            <Footer />
        </Layout>
    )
}

export default Privacy
